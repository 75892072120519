import React from "react";

export function McpedlIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="1em" height="1em" viewBox="0 0 16.0 16.0" version="1.1">
      <path d="m 15.457606,14.437834 -5.373287,-3.8e-5 V 1.2094488 h 3.19086 l -0.0056,10.3843012 c 0,0 0.01064,0.511719 0.445361,0.511719 0.484961,0 1.742762,-0.0081 1.742762,-0.0081 z" />
      <path d="M 4.4492188,1.209569 C 0.12823311,1.230832 0.49023437,1.2310305 0.49023438,1.2310305 V 14.435709 H 5.5976562 c 0,0 2.8529194,0.169695 2.7890626,-2.551967 -0.063857,-2.7216624 0,-7.8880794 0,-7.8880794 0,0 0.3834856,-2.8073566 -3.9375,-2.7860936 z M 4.0253906,3.3127966 c 1.1096602,-0.053024 1.2569362,0.7237134 1.2851563,1.104292 0.030102,0.4059504 -0.03125,6.3155354 -0.03125,6.3155354 0.042571,1.569392 -1.5058594,1.770988 -1.5058594,1.439872 0,-0.347114 0.015625,-8.836287 0.015625,-8.836287 0.082782,-0.011276 0.1623508,-0.019878 0.2363281,-0.023412 z" />
    </svg>
  );
}
