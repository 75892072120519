import React from "react";
import { FaYoutube } from "react-icons/fa";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// Button
function Button({ text, type, link }) {
  return (
    <Link className={`button button--${type}`} to={link}>
      {text}
    </Link>
  );
}

Button.defaultProps = {
  text: "Button",
  type: "download",
  link: "/404",
};
Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
};

// External Button
function ExternalButton({ text, type, link }) {
  return (
    <a className={`button button--${type}`} href={link}>
      {text}
    </a>
  );
}

ExternalButton.defaultProps = {
  text: "External Button",
  type: "download",
  link: "https://www.quazchick.com",
};
ExternalButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
};

// Social Button
function SocialButton({ platform, icon, tooltip }) {
  return (
    <a
      tooltip={tooltip}
      target="_blank"
      rel="noreferrer"
      aria-label={platform}
      className={`button button--social button--social--${platform}`}
      href={`https://www.quazchick.com/${platform}`}>
      {icon}
    </a>
  );
}

SocialButton.defaultProps = {
  tooltip: "YouTube",
  platform: "youtube",
  icon: <FaYoutube />,
};
SocialButton.propTypes = {
  tooltip: PropTypes.string,
  platform: PropTypes.string,
  icon: PropTypes.element,
};

// Small Button
function SmallButton({ content, type, link, focusable, tooltip }) {
  return (
    <Link tooltip={tooltip} tabIndex={focusable ? "0" : "-1"} className={`button button--small button--small--${type}`} to={link}>
      {content}
    </Link>
  );
}

SmallButton.defaultProps = {
  content: <FaYoutube />,
  type: "view",
  link: "/404",
  focusable: true,
  tooltip: "Small Button",
};
SmallButton.propTypes = {
  content: PropTypes.element,
  type: PropTypes.string,
  link: PropTypes.string,
  focusable: PropTypes.bool,
};

// External Button
function SmallExternalButton({ content, style, type, link, id, focusable, tooltip }) {
  return (
    <a
      tooltip={tooltip}
      id={id}
      tabIndex={focusable ? "0" : "-1"}
      style={style}
      target="_blank"
      rel="noreferrer"
      className={`button button--small button--small--${type}`}
      href={link}>
      {content}
    </a>
  );
}

SmallExternalButton.defaultProps = {
  content: <FaYoutube />,
  type: "view",
  link: "/404",
  focusable: true,
  tooltip: "External Small Button",
};
SmallExternalButton.propTypes = {
  content: PropTypes.element,
  type: PropTypes.string,
  link: PropTypes.string,
  style: PropTypes.element,
  id: PropTypes.string,
  focusable: PropTypes.bool,
};

// Export
export { Button, ExternalButton, SocialButton, SmallButton, SmallExternalButton };
