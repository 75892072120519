import React from "react";
import Seo from "../../components/seo";
import Default from "../../components/default";
import ResourcePacksList from "../../components/lists/resourcePacksList";
import PacksSectionNavigation from "../../components/sectionNavigations/packsSectionNavigation";
import Breadcrumbs from "../../components/breadcrumbs";
import { Link } from "gatsby";

const ResourcePacksPage = () => {
  return (
    <>
      <Seo title="Resource Packs" themeColor="#b13e44" />
      <Default>
        <section className="section--navigator">
          <Breadcrumbs>
            <Link to="/packs">Packs</Link>
            <span>Resource Packs</span>
          </Breadcrumbs>
          <div className="section--navigator__layout">
            <PacksSectionNavigation />
            <ResourcePacksList />
          </div>
        </section>
      </Default>
    </>
  );
};

export default ResourcePacksPage;
