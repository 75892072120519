import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SortListDropdown from "../dropdowns/sortListDropdown";
import { PackListItem } from "../templates/listItems";
import ShareModal from "../modals/shareModal";

const ResourcePacksList = () => {
  const data = useStaticQuery(
    graphql`
      query ResourcePacksList {
        newest: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { typeid: { eq: "resource-packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "D MMM YYYY")
                type
                mcpedlid
              }
            }
          }
        }
        oldest: allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter: { typeid: { eq: "resource-packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "D MMM YYYY")
                type
                mcpedlid
              }
            }
          }
        }
        popular: allMarkdownRemark(sort: { fields: [frontmatter___popularity], order: ASC }, filter: { frontmatter: { typeid: { eq: "resource-packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "D MMM YYYY")
                popularity
                type
                mcpedlid
              }
            }
          }
        }
      }
    `
  );

  const { popular, newest, oldest } = data;

  const windowSearch = typeof window !== `undefined` ? window.location.search : null;

  const queryParams = new URLSearchParams(windowSearch);
  const sort = queryParams.get("sort") === "popular" ? popular : queryParams.get("sort") === "oldest" ? oldest : newest;

  return (
    <div className="section--navigator__content">
      <SortListDropdown />

      {sort.edges.map(({ node }) => (
        <PackListItem title={node.frontmatter.title} date={node.frontmatter.date} slug={node.fields.slug} type={node.frontmatter.type} mcpedlid={node.frontmatter.mcpedlid} />
      ))}

      <ShareModal />
    </div>
  );
};

export default ResourcePacksList;
