import { Link } from "gatsby";
import React from "react";
import LogoIcon from "./icons/logoIcon";

export default function Breadcrumbs({ children }) {
  return (
    <breadcrumbs>
      <Link className="breadcrumb breadcrumb--home" to="/">
        <LogoIcon />
      </Link>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          className: "breadcrumb",
        })
      )}
    </breadcrumbs>
  );
}
