import React from "react";
import { FaSortAmountDown } from "react-icons/fa";
import { Dropdown, DropdownOption } from "../templates/dropdown";

const SortListDropdown = () => {
    return (
        <Dropdown title="Sort" icon={<FaSortAmountDown />}>
            <DropdownOption title="Most popular" link="?sort=popular" />
            <DropdownOption title="Date released (newest)" link="?sort=newest" />
            <DropdownOption title="Date released (oldest)" link="?sort=oldest" />
        </Dropdown>
    );
};

export default SortListDropdown;
