import React from "react";

export default function ArrowIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      width="1em"
      height="1em"
      viewBox="0 0 4.2333333 4.2333333"
      version="1.1"
      className={`icon--arrow ${className}`}>
      <path d="M 1.3232723,3.610062 2.9100759,2.1166667 1.3232724,0.62327133" />
    </svg>
  );
}
