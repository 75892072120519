import React from "react";
import { FaFacebookF, FaRedditAlien, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { SmallExternalButton } from "../templates/buttons";
import PropTypes from "prop-types";
import { Modal } from "../templates/modal";

function ShareModal({ slug, title, type }) {
  function copyLink() {
    var text = document.getElementById("share-link").innerText;
    var textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = text;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    document.getElementById("copy-share-link-button").innerText = "Copied!";
  }

  return (
    <Modal id="share" title="Share">
      <div className="modal-dialogue__copy-text">
        <p id="share-link" className="modal-dialogue__copy-text__text">
          https://quazchick.com{slug}
        </p>
        <button id="copy-share-link-button" onClick={copyLink} className="modal-dialogue__copy-text__copy">
          Copy
        </button>
      </div>
      <SmallExternalButton
        tooltip="Twitter"
        id="twitter-share-button"
        content={<FaTwitter />}
        type="share-link button--small--share-link--twitter"
        link={`https://twitter.com/intent/tweet?text=${title} - Minecraft ${type} by @QuazChick%0a%0a&url=https://quazchick.com${slug}`}
      />
      <SmallExternalButton
        tooltip="Reddit"
        id="reddit-share-button"
        content={<FaRedditAlien />}
        type="share-link button--small--share-link--reddit"
        link={`https://www.reddit.com/submit?title=${title} - Minecraft ${type} by QuazChick&url=https://quazchick.com${slug}`}
      />
      <SmallExternalButton
        tooltip="Whatsapp"
        id="whatsapp-share-button"
        content={<FaWhatsapp />}
        type="share-link button--small--share-link--whatsapp"
        link={`https://api.whatsapp.com/send?text=https://quazchick.com${slug}`}
      />
      <SmallExternalButton
        tooltip="Facebook"
        id="facebook-share-button"
        content={<FaFacebookF />}
        type="share-link button--small--share-link--facebook"
        link={`https://www.facebook.com/sharer/sharer.php?u=https://quazchick.com${slug}`}
      />
    </Modal>
  );
}

ShareModal.propTypes = {
  slug: PropTypes.element,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default ShareModal;
