import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { SmallButton, SmallExternalButton } from "./buttons";
import { McpedlIcon } from "../icons/mcpedlIcon";
import { ViewIcon } from "../icons/viewIcon";
import { ShareIcon } from "../icons/shareIcon";

// Pack List Item
function PackListItem({ title, date, slug, mcpedlid, type }) {
  const showMcpedl = mcpedlid === "none" ? false : true;

  function openShareModal() {
    document.getElementById("share-link").innerText = `https://quazchick.com${slug}`;

    document
      .getElementById("twitter-share-button")
      .setAttribute("href", `https://twitter.com/intent/tweet?text=${title} - Minecraft ${type} by @QuazChick%0a%0a&url=https://quazchick.com${slug}`);
    document
      .getElementById("reddit-share-button")
      .setAttribute("href", `https://www.reddit.com/submit?title=${title} - Minecraft ${type} by QuazChick&url=https://quazchick.com${slug}`);
    document.getElementById("whatsapp-share-button").setAttribute("href", `https://api.whatsapp.com/send?text=https://quazchick.com${slug}`);
    document.getElementById("facebook-share-button").setAttribute("href", `https://www.facebook.com/sharer/sharer.php?u=https://quazchick.com${slug}`);

    document.getElementById("share-modal").setAttribute("status", "open");
    document.getElementById("copy-share-link-button").innerText = "Copy";
    document.getElementById("share-modal-close").focus();
  }

  return (
    <div className="list-item list-item--small">
      <div className="list-item__icon-panel">
        <img alt="Pack Icon" class="list-item__icon-panel__icon" src={`https://assets.quazchick.com/images/icons/${title}.png`} />
        <div className="list-item__icon-panel__buttons-panel">
          <SmallButton tooltip="View" focusable={false} content={<ViewIcon />} type="view button" link={slug} />
          <button tooltip="Share" onClick={openShareModal} tabIndex={-1} className="button button--small button--small--share-modal">
            <ShareIcon />
          </button>
          <SmallExternalButton
            tooltip="MCPEDL"
            focusable={false}
            style={{ display: showMcpedl ? "inline-flex" : "none" }}
            content={<McpedlIcon />}
            type="mcpedl button"
            link={`https://mcpedl.com/${mcpedlid}`}
          />
        </div>
      </div>
      <Link className="list-item__text-panel" to={slug}>
        <h3 className="list-item__text">{title}</h3>
        <p className="list-item__text list-item__date">{date}</p>
      </Link>
    </div>
  );
}
PackListItem.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  mcpedlid: PropTypes.string,
};

// Pack List Item With Type
function PackListItemWithType({ title, date, slug, type, typeid, mcpedlid }) {
  const showMcpedl = mcpedlid === "none" ? false : true;

  function openShareModal() {
    document.getElementById("share-link").innerText = `https://quazchick.com${slug}`;

    document
      .getElementById("twitter-share-button")
      .setAttribute("href", `https://twitter.com/intent/tweet?text=${title} - Minecraft ${type} by @QuazChick%0a%0a&url=https://quazchick.com${slug}`);
    document
      .getElementById("reddit-share-button")
      .setAttribute("href", `https://www.reddit.com/submit?title=${title} - Minecraft ${type} by QuazChick&url=https://quazchick.com${slug}`);
    document.getElementById("whatsapp-share-button").setAttribute("href", `https://api.whatsapp.com/send?text=https://quazchick.com${slug}`);
    document.getElementById("facebook-share-button").setAttribute("href", `https://www.facebook.com/sharer/sharer.php?u=https://quazchick.com${slug}`);

    document.getElementById("share-modal").setAttribute("status", "open");
    document.getElementById("copy-share-link-button").innerText = "Copy";
    document.getElementById("share-modal-close").focus();
  }

  return (
    <div className="list-item list-item--small">
      <div className="list-item__icon-panel">
        <img alt="Pack Icon" class="list-item__icon-panel__icon" src={`https://assets.quazchick.com/images/icons/${title}.png`} />
        <div className="list-item__icon-panel__buttons-panel">
          <SmallButton tooltip="View" focusable={false} content={<ViewIcon />} type="view button" link={slug} />
          <button tooltip="Share" onClick={openShareModal} tabIndex={-1} className="button button--small button--small--share-modal">
            <ShareIcon />
          </button>
          <SmallExternalButton
            tooltip="MCPEDL"
            focusable={false}
            style={{ display: showMcpedl ? "inline-flex" : "none" }}
            content={<McpedlIcon />}
            type="mcpedl button"
            link={`https://mcpedl.com/${mcpedlid}`}
          />
        </div>
      </div>
      <Link className="list-item__text-panel" to={slug}>
        <h3 className="list-item__text">{title}</h3>
        <p className="list-item__text list-item__date">{date}</p>
      </Link>
      <Link to={`/packs/${typeid}`} className={`list-item__type list-item__type--${typeid}`}>
        {type}
      </Link>
    </div>
  );
}
PackListItemWithType.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  typeid: PropTypes.string,
  mcpedlid: PropTypes.string,
};

// Export
export { PackListItem, PackListItemWithType };
