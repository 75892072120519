import React from "react";
import SectionNavigationAd from "../advertisments/sectionNavigationAd";
import ArrowIcon from "../icons/arrowIcon";

export default function SectionNaviagtion({ children, title, accentColor, additionalLinks }) {
  return (
    <div className="section--navigator__navigation">
      <div className="section--navigator__navigation__content" style={{ borderTop: `4px solid ${accentColor}` }}>
        <h1 className="section--navigator__navigation__content__title">{title}</h1>
        <ArrowIcon className="section--navigator__navigation__content__arrow" />
        <ul>
          {React.Children.map(children, (child) => (
            <li>{child}</li>
          ))}
        </ul>
      </div>
      <div className="section--navigator__navigation__additional-links">{additionalLinks}</div>
      {/* <SectionNavigationAd /> */}
    </div>
  );
}
