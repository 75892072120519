import React from "react";
import { Link } from "gatsby";
import SectionNaviagtion from "../templates/sectionNavigation";

export default function PacksSectionNavigation() {
  return (
    <SectionNaviagtion
      title="Packs"
      accentColor="var(--pack-accent-color)"
      additionalLinks={
        <a className="button button--mcpedl" href="https://www.quazchick.com/mcpedl">
          View on MCPEDL
        </a>
      }>
      <Link
        className="section--navigator__navigation__content__link"
        activeClassName="section--navigator__navigation__content__link--active"
        activeStyle={{ background: "var(--pack-accent-color)" }}
        to="/packs">
        All Packs
      </Link>
      <Link
        className="section--navigator__navigation__content__link"
        activeClassName="section--navigator__navigation__content__link--active"
        activeStyle={{ background: "var(--add-on-accent-color)" }}
        to="/packs/add-ons">
        Add-Ons
      </Link>
      <Link
        className="section--navigator__navigation__content__link"
        activeClassName="section--navigator__navigation__content__link--active"
        activeStyle={{ background: "var(--resource-accent-color)" }}
        to="/packs/resource-packs">
        Resource Packs
      </Link>
      <Link
        className="section--navigator__navigation__content__link"
        activeClassName="section--navigator__navigation__content__link--active"
        activeStyle={{ background: "var(--behaviour-accent-color)" }}
        to="/packs/behaviour-packs">
        Behaviour Packs
      </Link>
    </SectionNaviagtion>
  );
}
