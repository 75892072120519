import React from "react";
import { FaSortAmountDown } from "react-icons/fa";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import ArrowIcon from "../icons/arrowIcon";

// This file contains the dropdown and dropdown option

// Dropdown

function Dropdown({ title, children, icon }) {
  return (
    <div className="dropdown">
      {icon}
      <h1>{title}</h1>
      <ArrowIcon className="dropdown__arrow" />
      <div class="dropdown__content">{children}</div>
    </div>
  );
}

Dropdown.defaultProps = {
  title: "Dropdown",
  icon: <FaSortAmountDown />,
  children: (
    <>
      <DropdownOption title="Option 1" link="?option=1" />
      <DropdownOption title="Option 2" link="?option=2" />
      <DropdownOption title="Option 3" link="?option=3" />
      <DropdownOption title="Option 4" link="?option=4" />
      <DropdownOption title="Option 5" link="?option=5" />
    </>
  ),
};

Dropdown.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
};

// Dropdown Option
function DropdownOption({ title, link }) {
  return (
    <Link tabIndex={-1} to={link} draggable="false">
      {title}
    </Link>
  );
}

DropdownOption.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
};

// Export
export { Dropdown, DropdownOption };
